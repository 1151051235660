import React, { Component } from 'react'
import { Provider } from 'react-redux'
import PropTypes from 'prop-types'

import { createTheme, CssGlobal, krowdyTheme } from '@krowdy/kds-core'
import { ThemeProvider } from '@krowdy/kds-styles'
import { SnackbarProvider } from 'notistack'
import DevTools from './DevTools'

class Root extends Component {
  render() {
    const { store, children } = this.props

    const theme = createTheme(krowdyTheme)

    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssGlobal />
          <SnackbarProvider
            anchorOrigin={{
              horizontal: 'center',
              vertical  : 'top'
            }}
            autoHideDuration={2000}
            maxSnack={3}>
            {children}
          </SnackbarProvider>
          <DevTools />
        </ThemeProvider>
      </Provider>
    )
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired
}

export default Root
