
import XDate from 'xdate'
import { getStoreTasksList } from 'directSelectors/taskConfigurations'

export const dateES = {
  dayNames       : [ 'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado' ],
  dayNamesShort  : [ 'Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb' ],
  monthNames     : [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ],
  monthNamesShort: [ 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Dic' ]
}

XDate.locales['es'] = dateES

export const TitleName = {
  HISTORY            : 'history',
  HUNTING            : 'hunting',
  MOVEMENTS          : 'movements',
  TRACKING           : 'tracking',
  VIDEO_CUESTIONNAIRE: 'video-questionnaire',
  VIDEO_INTERVIEW    : 'video-interview',
  VIDEO_PRESENTATION : 'video-presentation'
}

//* *configuracion de cantidad de tareas que se puede asignar un krowder */
export const Krowdy = {
  empty         : 'https://cdn.krowdy.com/media/images/Mesa_de_trabajo.svg',
  logo          : 'https://cdn.krowdy.com/media/images/logo.svg',
  maxAssigned   : 15,
  pageLimit     : 15,
  tableMaxPage  : 10,
  timeSuccescall: 19 }

export const TitleView = {
  HUNTING           : 'Hunting',
  SEGUIMIENTO       : 'Seguimiento',
  VIDEO_CUESTIONARIO: 'Video Cuestionario',
  VIDEO_ENTREVISTA  : 'Video Entrevista'
}

export const EstateTaskType = {
  DOING    : 'doing',
  EXECUTE  : 'execute',
  EXECUTED : 'executed',
  PENDING  : 'pending',
  REVIEW   : 'review',
  REVIEWED : 'reviewed',
  TRUNCATED: 'truncated'
}

export const EstateTaskSpanishType = {
  completed: 'Por Facturar',
  doing    : 'En proceso',
  execute  : '',
  executed : 'En proceso',
  invoiced : 'Facturada',
  pending  : 'Por realizar',
  review   : '',
  reviewed : '',
  truncated: 'Por facturar'
}

export const EstatePaymentType = {
  invoiced: 'Facturada',
  paid    : 'Pagado',
  pending : 'Por Facturar' // Por facturar
}

export const EstateTask = {
  EJECUCION   : 'Ejecución',
  NO_REVISAR  : 'No se Revisará',
  PENDIENTE   : 'Pendiente',
  POR_EJECUTAR: 'Por Ejecutar',
  POR_REVISAR : 'Por revisar',
  REVISADA    : 'Revisada',
  REVISION    : 'Revisión',
  SEGUIMIENTO : 'Proactivo',
  TRUNCADO    : 'Truncado'
}

export const ButtonCall = {
  CANDIDATE_ACTIVITY: {
    AddHistory  : true,
    infoFinished: 'El candidato tiene actividad reciente',
    typeFinished: 'CANDIDATE_ACTIVITY'
  },
  KROWDER_AFK: {
    AddHistory  : false,
    infoFinished: 'El seguimiento fue removido de tus tareas asignadas por inactividad',
    typeFinished: 'KROWDER_AFK'
  },
  TASK_COMPLETE: {
    AddHistory  : true,
    infoFinished: 'Se verificó una llamada exitosa',
    typeFinished: 'TASK_COMPLETE'
  },
  TRIES_LIMIT: {
    AddHistory  : true,
    infoFinished: 'Se ha llegado al limite de intentos',
    typeFinished: 'TRIES_LIMIT'
  }
}

export const dateRangeBalanceSemanal = [
  {  VE: 0, VOD: 0, name: 'L', nameComplet: 'Lunes' },
  {  VE: 0, VOD: 0, name: 'M', nameComplet: 'Martes' },
  {  VE: 0, VOD: 0, name: 'X', nameComplet: 'Miércoles' },
  {  VE: 0, VOD: 0, name: 'J', nameComplet: 'Jueves' },
  {  VE: 0, VOD: 0, name: 'V', nameComplet: 'Viernes' },
  {  VE: 0, VOD: 0, name: 'S', nameComplet: 'Sábado' },
  {  VE: 0, VOD: 0, name: 'D', nameComplet: 'Domingo' }
]

export const dateRangeBalanceMensual = [
  {  VE: 0,  VOD: 0, name: 'Ene', nameComplet: 'Enero' },
  {  VE: 0,  VOD: 0, name: 'Feb', nameComplet: 'Febrero' },
  {  VE: 0,  VOD: 0, name: 'Mar', nameComplet: 'Marzo' },
  {  VE: 0,  VOD: 0, name: 'Abr', nameComplet: 'Abril' },
  {  VE: 0,  VOD: 0, name: 'May', nameComplet: 'Mayo' },
  {  VE: 0,  VOD: 0, name: 'Jun', nameComplet: 'Junio' },
  {  VE: 0,  VOD: 0, name: 'Jul', nameComplet: 'Julio' },
  {  VE: 0,  VOD: 0, name: 'Ago', nameComplet: 'Agosto' },
  {  VE: 0,  VOD: 0, name: 'Set', nameComplet: 'Septiembre' },
  {  VE: 0,  VOD: 0, name: 'Oct', nameComplet: 'Octubre' },
  {  VE: 0,  VOD: 0, name: 'Nov', nameComplet: 'Noviembre' },
  {  VE: 0,  VOD: 0, name: 'Dic', nameComplet: 'Diciembre' }
]

export function dateFormatter(dateRange) {
  const data = dateRange === 3 ? [] : dateRange === 2 ? dateRangeBalanceMensual : dateRangeBalanceSemanal
  const format = dateRange === 3 ? 'yyyy' : dateRange === 2 ? 'MMM' : 'ddd'
  const formatFull = dateRange === 3 ? 'yyyy' : dateRange === 2 ? 'MMMM' : 'dddd'

  return { data, format, formatFull }
}

export function getUrl(data) {
  let url = ''

  const tasksList = getStoreTasksList()

  if(data.activityKrowderId)
  {
    const { nameTask, urlReview, urlRun, urlCertification } = tasksList[data.codeTask]

    // const isCuestionnaire = nameTask === TitleName.VIDEO_CUESTIONNAIRE
    // const isPresentation = nameTask === TitleName.VIDEO_PRESENTATION

    switch (nameTask) {
      case TitleName.VIDEO_CUESTIONNAIRE:
        if(data.certified)
          url = urlReview + data.resultTaskId
        else
          url = urlCertification + data.resultTaskId

        break

      case TitleName.VIDEO_INTERVIEW:
        if(data.certified) {
          if(data.status.includes(EstateTaskType.DOING) || data.status.includes(EstateTaskType.PENDING))
            url = urlRun + data.resultTaskId
          else if(data.status.includes(EstateTaskType.EXECUTED))
            url = urlReview + data.resultTaskId
        } else {
          // if(data.status.includes(EstateTaskType.DOING) || data.status.includes(EstateTaskType.PENDING))
          url = urlCertification + data.resultTaskId
        }
        break

      case TitleName.VIDEO_PRESENTATION:
        if(data.status.includes(EstateTaskType.DOING) || data.status.includes(EstateTaskType.PENDING))
          url = urlRun + data.resultTaskId
        else if(data.status.includes(EstateTaskType.EXECUTED))
          url = urlReview + data.resultTaskId
        break

      default:
        break
    }
  }

  return url
}

export function getWeeksInMonth(year, month, date, origin) {
  var weeks = [],
    firstDate = new Date(year, month, 1),
    lastDate = new Date(year, month + 1, 0),
    numDays = lastDate.getDate(),
    daycurrent = new Date(date).getDate(),
    weekcurrent = false

  var start = 1
  var end = 7 - firstDate.getDay()
  while (start <= numDays) {
    if(daycurrent >= start  && daycurrent <= end)
      weekcurrent = true

    weeks.push({ end: end, origin, start: start, weekcurrent })

    start = end + 1
    end = end + 7
    if(end > numDays)
      end = numDays
  }

  return weeks
}

export const calculateCreated = (updatedAt) => {
  const dateNow = XDate(new Date())
  const dateCreated =  XDate(updatedAt)

  const diffMin = parseInt(dateCreated.diffMinutes(dateNow))
  let diffHour = 0
  if(diffMin > 60)
    diffHour = parseInt(diffMin / 60)

  return `Hace ${diffHour >= 1 ? diffHour : diffMin} ${diffHour >= 1 ? 'h' : 'min'} `
}

export const  structureBalance = (codeTask, dateRange, balance, finishedAt, price) => {
  const { formatFull } = dateFormatter(dateRange)

  const tasksList = getStoreTasksList()

  const nameDate = new XDate(finishedAt).toString(formatFull, 'es')

  const { shortCodetask } = tasksList[codeTask]

  balance.forEach((el) => {
    if(el.nameComplet.toString() === nameDate)
    {
      el[shortCodetask] = el[shortCodetask] || 0
      el[shortCodetask] += price
    }
  })

  return balance
}

export function validEmails(email) {
  const pattern = new RegExp('^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$')

  return !pattern.test(email)
}

export function validUrlLinkedin(data) {
  const { linkVerification } = data
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

  let errorLink = false
  if(!pattern.test(linkVerification))
  {
    errorLink = true
  }
  else
  {
    const newstring = linkVerification.replace('https://www.linkedin.com/in/', 'linkedin/')
    const valuesname = newstring.split('/')
    if(valuesname[0] !== 'linkedin')
      errorLink = true
  }

  return errorLink
}

export function validTextSend(data) {
  const { text } = data

  const pattern = new RegExp('^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F ]*$')
  let errorText = false

  if(text.trim().length > 0)
  { if(!pattern.test(text))
    errorText = true
  }
  else {
    errorText = true
  }

  return errorText
}

export const keyLocalStorage = {
  Filter: 'FILTER_TASK_SELECT'
}

export const CodeTask = {
  Attraction                     : 'attraction',
  BackgroundCheck                : 'background-check',
  CandidateTrackings             : 'candidateTrackings',
  CreditHistory                  : 'credit-history',
  Cv                             : 'cv',
  DocumentRequest                : 'document-request',
  EventSchedule                  : 'event-schedule',
  ExternalValidation             : 'external-validation',
  Form                           : 'form',
  FormCall                       : 'form-call',
  Hunting                        : 'hunting',
  Invitations                    : 'invitation',
  KroconMassive                  : 'krocon-massive',
  KrowderConsulting              : 'krowder-consulting',
  OpenTest                       : 'open-test',
  OpenTestFuzzyNatura            : 'open-test-fuzzy-natura',
  OpenTestFuzzyNaturaCompetencies: 'open-test-fuzzy-natura-competencies',
  OutsideTest                    : 'outside-test',
  Profile                        : 'profile',
  Ranking                        : 'ranking',
  SecurityReferences             : 'security-references',
  TestOnline                     : 'test-online',
  TestOnlineMultiTest            : 'test-online-multitest',
  Tracking                       : 'tracking',
  VideoInterview                 : 'video-interview',
  VideoPresentation              : 'video-presentation',
  VideoQuestionnaire             : 'video-questionnaire'
}
